import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private _HttpClient:HttpClient) { }
  PagedUsers(pager:any):Observable<any>{
    return this._HttpClient.post(`${environment.Server_URL}/User/PagedUsers`,pager);
   }
  GetAllUsers():Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/User/GetAllUsers`);
   }

   GetUserById(id:any):Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/User/GetUserById?id=${id}`);
   }

   Create(data):Observable<any>{
   return this._HttpClient.post(`${environment.Server_URL}/User/Create`, data  );
  }

  Login(data):Observable<any>{
    return this._HttpClient.post(`${environment.Server_URL}/User/Login`, data  );
   }
   ChangePassword(data):Observable<any>{
    return this._HttpClient.post(`${environment.Server_URL}/User/ChangePassword`, data  );
   }

   Update(data):Observable<any>{
    return this._HttpClient.post(`${environment.Server_URL}/User/UpdateUser`, data  );
   }
 
   Delete(id):Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/User/DeleteUser?userId=${id}` ); 
  }
}
