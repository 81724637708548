export const environment = {
  production: true,

  //Old Api

  // Server_URL:`https://mrscoolapidemo.azurewebsites.net/api/`,
  // Server_URL_Image:'https://mrscoolapidemo.azurewebsites.net/',


  // Server_URL:`https://mrscoolapi.azurewebsites.net/api/`,
  // Server_URL_Image:'https://mrscoolapi.azurewebsites.net/',


  // Server_URL:`https://mrscoolapilive.azurewebsites.net/api/`,
  // Server_URL_Image:'https://mrscoolapilive.azurewebsites.net/',
////////////////////////////////////////////////////////////////////////
  
//New Api Test
  
  // Server_URL:`https://alnada-devmrsapi.azurewebsites.net/api/`,
  // Server_URL_Image:'https://alnada-devmrsapi.azurewebsites.net/',
  

//New Api Live

   Server_URL:`https://api.mrscool.app/api/`,
  Server_URL_Image:'https://api.mrscool.app/',
  
  
};
