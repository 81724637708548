import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DragulaModule } from "ng2-dragula";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';

// Components
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { ContentComponent } from "./components/layout/content/content.component";
import { FullComponent } from "./components/layout/full/full.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { TapToTopComponent } from "./components/tap-to-top/tap-to-top.component";
// Header Elements Components
import { SearchComponent } from "./components/header/elements/search/search.component";
import { LanguagesComponent } from "./components/header/elements/languages/languages.component";
import { NotificationComponent } from "./components/header/elements/notification/notification.component";
import { BookmarkComponent } from "./components/header/elements/bookmark/bookmark.component";
import { CartComponent } from "./components/header/elements/cart/cart.component";
import { MessageBoxComponent } from "./components/header/elements/message-box/message-box.component";
import { MyAccountComponent } from "./components/header/elements/my-account/my-account.component";

// Services
import { LayoutService } from "./services/layout.service";
import { NavService } from "./services/nav.service";
import { DecimalPipe } from "@angular/common";
import { SvgIconComponent } from "./components/svg-icon/svg-icon.component";
import { CarouselModule } from "ngx-owl-carousel-o";
import { SwiperModule } from "swiper/angular";
import { SwiperComponent } from "./components/header/elements/swiper/swiper.component";
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {OnlyNumbersDirective} from 'src/app/shared/directives/only-numbers.directive'
import {NumericDecimalOnlyDirective} from 'src/app/shared/directives/NumericDecimalOnly.directive'
import {DisableWeritingArabicDirective} from 'src/app/shared/directives/disable-weriting-arabic.directive'
import {DisableWeritingEnglishDirective} from 'src/app/shared/directives/disable-weriting-english.directive'
import {ButtonDisableDirective} from 'src/app/shared/directives/ButtonDisable.directive';
import { NonZeroDirective } from './directives/non-zero.directive'
import { DxReportViewerModule } from "devexpress-reporting-angular";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    FullComponent,
    LoaderComponent,
    TapToTopComponent,
    SearchComponent,
    LanguagesComponent,
    NotificationComponent,
    BookmarkComponent,
    CartComponent,
    MessageBoxComponent,
    MyAccountComponent,
    SvgIconComponent,
    SwiperComponent,
    OnlyNumbersDirective,
    NumericDecimalOnlyDirective,
    NonZeroDirective,
    DisableWeritingArabicDirective,
    DisableWeritingEnglishDirective,
    ButtonDisableDirective,
    NonZeroDirective
  ],
  imports: [CommonModule, DxReportViewerModule,   PaginationModule.forRoot(),    TooltipModule.forRoot(),
    TranslateModule.forChild(),
     RouterModule, FormsModule, ReactiveFormsModule, NgbModule, DragulaModule.forRoot(), CarouselModule, SwiperModule],
  providers: [NavService, LayoutService, DecimalPipe,NgSelectModule],
  exports: [NgbModule,NgSelectModule, FormsModule,OnlyNumbersDirective,NonZeroDirective,NumericDecimalOnlyDirective,DisableWeritingArabicDirective,DisableWeritingEnglishDirective,ButtonDisableDirective,PaginationModule,TooltipModule, ReactiveFormsModule, TranslateModule, LoaderComponent, BreadcrumbComponent, FeatherIconsComponent, TapToTopComponent, SvgIconComponent, SwiperModule],
})
export class SharedModule {}
