import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumericDecimalOnly]'
})
export class NumericDecimalOnlyDirective {
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
    if (
      // Allow special keys like Backspace, Delete, arrow keys, and Tab
      allowedKeys.includes(event.key) ||
      // Allow numbers and a single decimal point
      /[0-9.]/.test(event.key) ||
      // Allow Ctrl (for copy-pasting)
      (event.ctrlKey && event.key === 'v')
    ) {
      return; // Allow the event to continue normally
    }
    event.preventDefault(); // Prevent any other key from being pressed
  }

}
