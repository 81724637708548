import { Router } from '@angular/router';
// import { ChangePassWord } from '../../../shared//Models/change-pass-word';
import { UserService } from 'src/app/shared/API-Service/user.service'
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
//  modelChangepassword = new ChangePassWord
  public ChangePassForm: FormGroup;
  validated: boolean;

  constructor(private translate: TranslateService,private fb: FormBuilder,private router:Router,private UserService:UserService) {
    
   }

  ngOnInit(): void {
    this.ChangePassForm = this.fb.group({
      currentPassword: [null, Validators.required],
      newPassword: [null, Validators.required],
    });
  }
  get fc() {
    return this.ChangePassForm.controls;
  }
  changePassword(){
    Swal.fire({
      position: 'center',   
      title: this.translate.instant('Are You Sure To Change Password'),
      showCancelButton: true,
      confirmButtonText:  this.translate.instant('Yes'),
      cancelButtonText: this.translate.instant('No'),
      showLoaderOnConfirm: true,
      preConfirm: (phone) => {
        const data={
          "currentPassword": this.ChangePassForm.get('currentPassword').value,
          "newPassword": this.ChangePassForm.get('newPassword').value,
        }
        
       
          this.UserService.ChangePassword(data).subscribe(response=>{
      if (response) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: this.translate.instant('Password Changed Successfully'),
          showConfirmButton: false,
          timer: 1500
        })
        this.ChangePassForm.reset();
      }
    },err=>{
      Swal.fire({
        icon: 'error',
        title:this.translate.instant('Error'),
        text:err.error.message
      })
     })
      }
    })
  
  }

submit(){
  if (this.ChangePassForm.status == "VALID") {
    this.validated = false;
    
    this.changePassword();
  
    } else {
    this.validated = true;
    }
}
  back(){
    history.back()
  }

}
