import { Directive, HostListener, ElementRef } from '@angular/core';
import { ButtonDisableService } from '../API-Service/ButtonDisable.service';
import { take } from 'rxjs';

@Directive({
  selector: '[appButtonDisable]'
})
export class ButtonDisableDirective {

  constructor(
    private buttonDisableService: ButtonDisableService,
    private elementRef: ElementRef<HTMLButtonElement>
  ) {
    this.buttonDisableService.getButtonState().subscribe((state) => {
      this.elementRef.nativeElement.disabled = state;
    });
  }
 
}
