import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Menu, NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {

  public iconSidebar;
  public menuItems: Menu[];
  public url: any;
  public fileurl: any;

  // For Horizontal Menu
  public margin: any = 0;
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = false;

  constructor(private router: Router, public navServices: NavService,private translate:TranslateService,
    public layout: LayoutService) {
    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems;
      console.log(this.menuItems)
      // this.router.events.subscribe((event) => {
      //   if (event instanceof NavigationEnd) {
          
      //     menuItems.filter(items => {
      //       if (items.path === event.url) {
      //         this.setNavActive(items);
      //       }
      //       if (!items.children) { return false; }
      //       items.children.filter(subItems => {
      //         if (subItems.path === event.url) {
      //           this.setNavActive(subItems);
      //         }
      //         if (!subItems.children) { return false; }
      //         subItems.children.filter(subSubItems => {
      //           if (subSubItems.path === event.url) {
      //             this.setNavActive(subSubItems);
      //           }
      //         });
      //       });
      //     });
      //   }
      // });
      this.navServices.items.subscribe(menuItems => {
        this.menuItems = menuItems;
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            // Find the Reports menu item
            const reportsMenuItem = menuItems.find(item => item.title === this.translate.instant('Reports'));
            const manageTeachersMenuItem = menuItems.find(item => 
              item.title === this.translate.instant('Manage Teachers')
          );
            const teacherMenuItem = manageTeachersMenuItem?.children?.find(child => 
              child.title === this.translate.instant('Teachers')
          );
            // If the URL matches any report-related path
            const reportPaths = [
              '/content/admin/report'
            ];
            const teacherPaths = [
              '/content/admin/teacher-details'
            ];
            
            if (reportPaths.includes(event.url)) {
              if (reportsMenuItem) {
                // Deactivate all menu items first
                menuItems.forEach(item => {
                  item.active = false;
                  if (item.children) {
                    item.children.forEach(child => child.active = false);
                  }
                });
                
                // Activate Reports menu item
                reportsMenuItem.active = true;
              }
              return; // Stop further processing
            }
            if (teacherPaths.some(path => event.url.startsWith(path))) {
              if (teacherMenuItem && manageTeachersMenuItem) {
                  // Deactivate all menu items first
                  menuItems.forEach(item => {
                      item.active = false;
                      if (item.children) {
                          item.children.forEach(child => child.active = false);
                      }
                  });
                  
                  // Activate both parent and child menu items
                  manageTeachersMenuItem.active = true;
                  teacherMenuItem.active = true;
              }
              return;
          }
            // Existing navigation logic
            menuItems.some(items => {
              if (items.path === event.url) {
                this.setNavActive(items);
                return true;
              }
              if (!items.children) { return false; }
              return items.children.some(subItems => {
                if (subItems.path === event.url) {
                  this.setNavActive(subItems);
                  return true;
                }
                if (!subItems.children) { return false; }
                return subItems.children.some(subSubItems => {
                  if (subSubItems.path === event.url) {
                    this.setNavActive(subSubItems);
                    return true;
                  }
                });
              });
            });
          }
        });
      });
    });

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth - 500;
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  // Active Nave state
  setNavActive(item) {
    
    if (item === 'content/admin/report') {
      const reportsMenuItem = this.menuItems.find(menuItem => menuItem.title === 'Reports');
      if (reportsMenuItem) {
          reportsMenuItem.active = true;
          return;
      }
  }

    this.menuItems.filter(menuItem => {
      
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) { return false; }
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }


  // For Horizontal Menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }

  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464;
      this.leftArrowNone = false;
      this.rightArrowNone = true;
    } else {
      this.margin += -this.width;
      this.leftArrowNone = false;
    }
  }


}
