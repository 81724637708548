// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
};
// Old Api
//  export const Server_URL=`https://mrscoolapidemo.azurewebsites.net/api/`;


//  export const Server_URL=`https://mrscoolapi.azurewebsites.net/api/`;

// export const Server_URL=`https://mrscoolapilive.azurewebsites.net/api/`;

///////////////////////////////////////////////////////////////////////////////

// New Api Test
  // export const Server_URL=`https://alnada-devmrsapi.azurewebsites.net/api/`;

  //New Api Live
  export const Server_URL=`https://api.mrscool.app/api/`;

  