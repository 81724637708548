<div class="media profile-media">
  <img class="b-r-10" src="assets/images/dashboard/profile.png" alt="">
  <div class="media-body"><span>{{userName}}</span>
    <p class="mb-0 font-roboto">{{RoleTitle}} <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <!-- <li><a href="/user/profile"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li>
  <li><a href="email"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li>
  <li><a href="/to-do"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li>
  <li><a href="/faq"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li> -->
  <li (click)="changePassword()"><app-feather-icons [icon]="'edit'"></app-feather-icons><span style="font-size: small;">{{'Change Password' | translate}}</span></li>
  <li (click)="logoutFunc()"><app-feather-icons [icon]="'log-out'"></app-feather-icons><span>{{'Log Out' | translate}}</span></li>
</ul>