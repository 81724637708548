import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import {UserService} from 'src/app/shared/API-Service/user.service';
import Swal from "sweetalert2";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public show: boolean = false
  public errorMessage: any;

  constructor(private UserService:UserService,private fb: FormBuilder, public router: Router) {
    this.loginForm = this.fb.group({
      username: ["", [Validators.required]],
      password: ["", Validators.required],
    });
  }

  ngOnInit() {}

  login() {
    // if (this.loginForm.value["email"] == "Test@gmail.com" && this.loginForm.value["password"] == "test123") {
    //   let user = {
    //     email: "Test@gmail.com",
    //     password: "test123",
    //     name: "test user",
    //   };
    //   localStorage.setItem("user", JSON.stringify(user));
    //   this.router.navigate(["/content/admin/academic-semester"]);
    // }
    const data ={
  "userName": this.loginForm.get('username').value,
  "password":  this.loginForm.get('password').value,
    }
    this.UserService.Login(data).subscribe((res) => {
      
      Swal.fire({
        icon: "success",
        title: "Signed in successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      localStorage.setItem('Authorization',res.data["token"]);
      localStorage.setItem('username',res.data["userName"]);
      localStorage.setItem("UserData",JSON.stringify(res.data));

      this.router.navigate(["/content/admin/Dashboard"]);
    },(err) =>{
console.log("their is an error");
Swal.fire({
  icon: 'error',
  title: 'failed to sign in',
  text:err.error.message    
})
}, () =>{
  console.log("completed");
  
});


  }

  showPassword(){
    this.show = !this.show
  }
}
