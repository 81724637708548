import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';

@Directive({
  selector: '[nonZeroDirective]'
})
export class NonZeroDirective {
  constructor(
    private el: ElementRef,
    private tooltip: TooltipDirective,
    private renderer: Renderer2
  ) {}

  @HostListener('input') onInput() {
    const value = this.el.nativeElement.value;
    if (value === '0') {
      this.renderer.setProperty(this.el.nativeElement, 'title', 'Value cannot be 0');
      this.tooltip.hide();
      this.tooltip.show();
    } else {
      this.renderer.removeAttribute(this.el.nativeElement, 'title');
      this.tooltip.hide();
    }
  }
}
