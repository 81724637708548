import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./auth/login/login.component";
import { ChangePasswordComponent } from "./auth/changepassword/change-password.component";

import { ContentComponent } from "./shared/components/layout/content/content.component";
import { FullComponent } from "./shared/components/layout/full/full.component";
import { full } from "./shared/routes/full.routes";
import { content } from "./shared/routes/routes";
import {IsNotLoginGuard}  from "src/app/shared/services/is-not-login.guard"
import {IsLoginGuard}  from "src/app/shared/services/is-login.guard"
import { ListEducationTypeComponent } from "./components/admin/EducationType/list-education-type/list-education-type.component";

const routes: Routes = [
  {
    path: '',
    canActivate:[IsNotLoginGuard],
    component:LoginComponent,
  },
  {
    path: 'login',
    canActivate: [IsNotLoginGuard],
    component:LoginComponent
  },

  {
    path: "content",
    canActivate: [IsLoginGuard],
    component: ContentComponent,
    children: content

  },

  {
    path: "",
    canActivate: [IsLoginGuard],
    component: FullComponent,
    children: full


  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [
    [
      RouterModule.forRoot(routes, {
        anchorScrolling: "enabled",
        scrollPositionRestoration: "enabled",
      }),
    ],
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
