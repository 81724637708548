import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router,private translate: TranslateService) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    {
      headTitle1: this.translate.instant('Pages')
    },
    {
      title: this.translate.instant('Main'),
      icon: "home",
      type: "sub",
      badgeType: "light-primary",
      children: [
        { path: "/content/admin/Dashboard", title: this.translate.instant('Statistics'), type: "link" },
      ],
    },
    {
      title: this.translate.instant('Manage Users'),
      icon: "calendar",
      type: "sub",
      badgeType: "light-primary",
      children: [
        { path: "/content/admin/user", title: this.translate.instant('Users'), type: "link" },
      ],
    },
    {
      title: this.translate.instant('Manage Education Type'),
      icon: "briefcase",
      type: "sub",
      badgeType: "light-primary",
      active: true,
      children: [
        { path: "/content/admin/education-type", title: this.translate.instant('Education Types'), type: "link" },
      ],
    },
    {
      title: this.translate.instant('Manage Education Levels'),
      icon: "list",
      type: "sub",
      badgeType: "light-primary",
      children: [
        { path: "/content/admin/education-level", title: this.translate.instant('Education Levels'), type: "link" },
        { path: "/content/admin/courses", title: this.translate.instant('Courses'), type: "link" },

      ],
    },
    {
      title: this.translate.instant('Manage Academic Years'),
      icon: "calendar",
      type: "sub",
      badgeType: "light-primary",
      children: [
        { path: "/content/admin/academic-year", title: this.translate.instant('Academic Years'), type: "link" },
      ],
    },
    {
      title: this.translate.instant('Manage acadmic semester'),
      icon: "book",
      type: "sub",
      badgeType: "light-primary",
      children: [
        { path: "/content/admin/academic-semester", title: this.translate.instant('Academic Semesters'), type: "link" },
      ],
    },
    {
      title: this.translate.instant('Manage Subject'),
      icon: "calendar",
      type: "sub",
      badgeType: "light-primary",
      children: [
        { path: "/content/admin/subject", title: this.translate.instant('Subject'), type: "link" },
      ],
    },
    {
      title: this.translate.instant('Manage Subject Semester'),
      icon: "calendar",
      type: "sub",
      badgeType: "light-primary",
      children: [
        { path: "/content/admin/subject-semester-year", title: this.translate.instant('Subject Semester Year'), type: "link" },
      ],
    },
    {
      title: this.translate.instant('Manage Countries'),
      icon: "calendar",
      type: "sub",
      badgeType: "light-primary",
      children: [
        { path: "/content/admin/country", title: this.translate.instant('Country'), type: "link" },
      ],
    },
    {
      title: this.translate.instant('Manage Governorate'),
      icon: "calendar",
      type: "sub",
      badgeType: "light-primary",
      children: [
        { path: "/content/admin/governorate", title: this.translate.instant('Governorate'), type: "link" },
      ],
    },
    {
      title: this.translate.instant('Manage City'),
      icon: "calendar",
      type: "sub",
      badgeType: "light-primary",
      children: [
        { path: "/content/admin/city", title: this.translate.instant('City'), type: "link" },
      ],
    },
    {
      title: this.translate.instant('Manage Unit'),
      icon: "calendar",
      type: "sub",
      badgeType: "light-primary",
      children: [
        { path: "/content/admin/unit", title: this.translate.instant('Unit'), type: "link" },
      ],
    },
    {
      title: this.translate.instant('Manage Teachers'),
      icon: "calendar",
      type: "sub",
      badgeType: "light-primary",
      children: [
        { path: "/content/admin/teachers", title: this.translate.instant('Teachers'), type: "link" },
      ],
    },
    {
      title: this.translate.instant('Manage Transactions'),
      icon: "calendar",
      type: "sub",
      badgeType: "light-primary",
      children: [
        { path: "/content/admin/transactions", title: this.translate.instant('Transactions'), type: "link" },
      ],
    },
    {
      title: this.translate.instant('Manage Banks'),
      icon: "calendar",
      type: "sub",
      badgeType: "light-primary",
      children: [
        { path: "/content/admin/bank", title: this.translate.instant('Bank'), type: "link" },
      ],
    },
    {
      title: this.translate.instant('Manage Material Type'),
      icon: "calendar",
      type: "sub",
      badgeType: "light-primary",
      children: [
        { path: "/content/admin/material-type", title: this.translate.instant('Material Types'), type: "link" },
      ],
    },
    {
      title: this.translate.instant('Manage Document Type'),
      icon: "list",
      type: "sub",
      badgeType: "light-primary",
      children: [
        { path: "/content/admin/document-type", title: this.translate.instant('Document Type'), type: "link" },
      ],
    },
    {
      title: this.translate.instant('Manage Settings'),
      icon: "list",
      type: "sub",
      badgeType: "light-primary",
      children: [
        { path: "/content/admin/setting", title: this.translate.instant('Setting'), type: "link" },
      ],
    },
    {
      title: this.translate.instant('Reports'),
      icon: "file-text",
      type: "sub",
      badgeType: "light-primary",
      children: [
        { path: "/content/admin/teacher-report", title: this.translate.instant('Teacher Report'), type: "link" },
        { path: "/content/admin/teacher-groups-report", title: this.translate.instant('Teacher Groups Report'), type: "link" },
      ],
    },
    {
      title: this.translate.instant('Edit-Translate'),
      icon: "file-text",
      type: "sub",
      badgeType: "light-primary",
      children: [
        { path: "/content/admin/Edit-Translate-For-MrsCol-Web", title: this.translate.instant('Edit-Translate-For-MrsCol-Web'), type: "link" },
      ],
    },
    
  ];


  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
