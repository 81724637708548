
<app-breadcrumb [title]="'Change Password' | translate" [active_item]="'Form Wizard'"></app-breadcrumb>
<div class="container-fluid">
<div class="row ">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <form class="needs-validation" [formGroup]="ChangePassForm" (ngSubmit)="submit()" novalidate
                [ngClass]="{'was-validated': validated}">
            <div class="row">
                <div class="col-12">
                    <!-- <label for=""></label> -->
                    <div  ><label for=""> {{'Old Password' | translate}}</label></div>
                    <input type="password"  id="validationCustom01" formControlName="currentPassword"   class="form-control"
                    [tooltip]="fc.currentPassword.errors?.required ? ('Old Password Required' | translate)  : null"
                    required                                         
                    >
                </div>
                <div class="col-12 mt-3">
                    <!-- <label for="">رقم السر الجديده</label> -->
                   
                    <div class="form-group" >
                         <div ><label for=""> {{'New Password' | translate}}</label></div>
                        
                        <input class="form-control"  id="validationCustom01" formControlName="newPassword"  type="password"   
                        [tooltip]="fc.newPassword.errors?.required ? ('New Password Required' | translate)  : null"
                        required                                       
                          >
                      </div>
                </div>
            </div>
        
            <div class="d-flex justify-content-end align-items-center">
                <!-- <button (click)="back()" style="    margin-left: 10px;
                padding: 9px 24px;
                font-size: 14px;
                background: red;
                margin-top: 20px;
                color: white;">رجوع</button> -->
                <button style="    color: #fff;
                background: #0e0d64;
                padding: 7px 17px;
                margin-top: 21px;
                font-size: 16px;" type="submit">{{'Edit' | translate}}</button>
               
            </div>
                </form>
        </div> 
        </div>
    </div>
   
</div>
</div>