import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DefaultlangService {
  private langCodeSubject = new BehaviorSubject<string>('en');
  langCode$ = this.langCodeSubject.asObservable();
  constructor() {
    const storedValue = JSON.parse(localStorage.getItem('selectedLanguage'))?.code || 'en';
    this.setLangCode(storedValue);
  }
  setLangCode(langCode: string) {
    this.langCodeSubject.next(langCode);
    // localStorage.setItem('selectedLanguage', JSON.stringify({ code: langCode }));
  }

  getLangCode(): string {
    return this.langCodeSubject.getValue();
  }
}
